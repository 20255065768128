import React from "react";
import PropTypes from "prop-types";
import Layout from "../Layout/MasterLayout/MasterLayout";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBIcon,
	MDBBtn,
	MDBCard,
	MDBCardImage,
	MDBCardBody,
	MDBCardText,
} from "mdbreact";

export default function AmbientesAprendizagem({ pageContext: { ambientes } }) {
	AmbientesAprendizagem.propTypes = {
		pageContext: PropTypes.object.isRequired,
		ambientes: PropTypes.object.isRequired, // Array de ambientes
	};

	// Define os botões de avanço e retorno do carousel
	const ButtonGroup = ({ next, previous }) => {
		return (
			<>
				<MDBIcon
					className="text-black d-none d-md-block fgprimary-gradient"
					style={{
						position: "absolute",
						zIndex: 1000,
						left: "-4%",
						backgroundColor: "transparent",
						cursor: "pointer",
					}}
					icon="chevron-left"
					size="3x"
					onClick={() => previous()}
				/>
				<MDBIcon
					className="text-black d-none d-md-block fgprimary-gradient"
					style={{
						position: "absolute",
						zIndex: 1000,
						right: "-4%",
						backgroundColor: "transparent",
						cursor: "pointer",
					}}
					icon="chevron-right"
					size="3x"
					onClick={() => next()}
				/>

				<MDBRow
					className="d-flex d-md-none"
					style={{
						position: "absolute",
						top: "-3%",
						left: "50%",
						transform: "translateX(-42%)",
					}}
				>
					<MDBCol className="d-flex p-0 pr-4">
						<MDBBtn className="arrow-carrousel m-0" onClick={() => previous()}>
							<span className="d-flex">
								<MDBIcon icon="chevron-left" className="p-2" />
							</span>
						</MDBBtn>
					</MDBCol>
					<MDBCol className="d-flex p-0 pl-4">
						<MDBBtn className="arrow-carrousel m-0" onClick={() => next()}>
							<span className="d-flex">
								<MDBIcon icon="chevron-right" className="p-2" />
							</span>
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</>
		);
	};

	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1025 },
			items: 3,
			slidesToSlide: 3, // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 465 },
			items: 2,
			slidesToSlide: 2, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	return (
		<Layout>
			<MDBContainer>
				<MDBRow>
					<MDBCol>
						<h1 className="pageTitle py-5 fgprimary-gradient d-none d-md-block">
							ambientes de aprendizagem
						</h1>
						<h1
							className="pageTitle py-5 fgprimary-gradient d-block d-md-none"
							style={{ fontSize: "3em" }}
						>
							ambientes de aprendizagem
						</h1>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p>
							Queremos contribuir com a formação educacional e humana dos nossos
							estudantes. Para isso, proporcionamos vivências de aprendizagem em
							meio à natureza, além de experiências que potencializam o
							desenvolvimento cognitivo e reflexivo dos estudantes, em prol de
							um mundo mais equilibrado, justo e humano.
						</p>
					</MDBCol>
				</MDBRow>
				{ambientes !== null && ambientes.length > 0 && (
					<MDBRow>
						<MDBCol className="pt-5 mt-3 pt-md-3">
							<Carousel
								swipeable={true}
								draggable={false}
								showDots={false}
								responsive={responsive}
								ssr={true} // means to render carousel on server-side.
								infinite={ambientes.length > 1 ? true : false}
								autoPlay={ambientes.length > 1 ? true : false}
								autoPlaySpeed={5000}
								keyBoardControl={true}
								transitionDuration={500}
								arrows={false}
								renderButtonGroupOutside={false}
								customButtonGroup={<ButtonGroup />}
								containerClass="carousel-container"
							>
								{ambientes?.map((ambiente, index) => (
									<>
										<MDBCard className="mx-1 mx-md-3 h-100">
											{ambiente?.Configuracoes?.ImagemDestaque?.Media
												?.PublicUrl !== null && (
												<MDBCardImage
													className="card-img-top"
													src={
														ambiente?.Configuracoes?.ImagemDestaque?.Media
															?.PublicUrl
													}
													waves={false}
													style={{
														maxHeight: "200px",
														objectFit: "cover",
													}}
												/>
											)}
											<MDBCardBody className="pb-3 px-3">
												<h4
													className="fgprimary-gradient text-center card-title"
													style={{ fontWeight: 500 }}
												>
													{ambiente.MetaTitle}
												</h4>
												<hr className="p-0 m-0" />
												<MDBCardText className="text-primary align-middle py-2 list-inline-item">
													<MDBRow>
														<MDBCol className="float-left pt-2 text-mute colorLetter">
															<p
																dangerouslySetInnerHTML={{
																	__html:
																		ambiente?.Configuracoes?.Descricao?.Value,
																}}
															></p>
														</MDBCol>
													</MDBRow>
												</MDBCardText>
											</MDBCardBody>
										</MDBCard>
									</>
								))}
							</Carousel>
						</MDBCol>
					</MDBRow>
				)}
			</MDBContainer>
		</Layout>
	);
}
